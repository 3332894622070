import log from './Logger'
function DefineColumns (returnedColumns) {
    const tableStructure = [
        { keyName: "id", label: "The Id", index: 99 }
        , { keyName: "code", label: "Bet Code", index: 98 }
        , { keyName: "date_uploaded", label: "Bet Uploaded Date", index: 97 }
        , { keyName: "active", label: "Bet Active", index: 96 }
        , { keyName: "date_created", label: "Bet Created Date", index: 1 }
        , { keyName: "valid_bet", label: "Bet Valid", index: 2 }
    ]

    let columns = returnedColumns?.map((currentColumn, index) => {
        let header = tableStructure.find(tableColumn => tableColumn.keyName === currentColumn)
        if (!header)
            header = {
                keyName: currentColumn,
                label: currentColumn,
                index: index + 10
            }

        const column = {
            field: header.keyName,
            headerName: header.label,
            sortable: true,
            filter: true,
            resizable: true,
            wrapText: true,    
            autoHeight: true,
            headerTooltip: header.tooltip? header.tooltip:header.label,
            cellStyle:{ padding:5, fontSize: 12, whiteSpace: "normal", lineHeight:2},             
        }
        if (header.keyName === "active" || header.keyName === "valid_bet") {
            column.cellRenderer = (params) => params.value ? 'Yes' :'No'
        }
        return {value: column, sortIndex: header.index}
    })
    if (!columns)
        columns = []
    log(columns)    
    const sortedColumns = columns.sort((a, b) => a.sortIndex - b.sortIndex).map(column => column.value)
    log(sortedColumns)    
    return sortedColumns
}
export const Helpers = {
    DefineColumns
}